import fetch from '../fetch'

// 用户登录
const login = (data) => {
    return fetch('auth/login', 'POST', data)
}
const articleList = (data) => {
    return fetch('article/list?'+ data,'GET','')
}
const articleTypeList = () => {
    return fetch('article/type/list','GET','')
}
const articleByType = (data) => {
    return fetch('article/byType','GET',data)
}
// 添加管理员
const addAdmin = (data) => {
    return fetch('auth/register', 'POST', data)
}
// 获取所有的管理员账户
const adminList = (data) => {
    return fetch('admin/list', 'GET', data)
}
// 修改管理员账户
const removeAdmin = (data) => {
    return fetch('admin', 'PUT', data)
}
// 根据id获取管理员的详细信息
const adminDetail = (data) => {
    return fetch('admin/'+data, 'GET', '')
}
// 修改管理员信息
const adminUpdate = (data) => {
    return fetch('admin', 'PUT', data)
}
// 根据id删除管理员账户
const adminRemove = (data) => {
    return fetch('admin/' + data, 'DELETE', '')
}

const userList = (data) => {
    return fetch('user?'+data, 'GET', '')
}
const userDetail = (data) => {
    return fetch('user/' + data, 'GET', '')
}
// 获取符合条件的全部工程师
const engineerList = (data) => {
    return fetch('engineer/list', 'GET', data)
}
// 获取所有条件的全部工程师
const engineerAllList = (data) => {
    return fetch('engineer/allList', 'GET', data)
}
// 修改指定id工程师详情
const updateEngineer = (data) => {
    return fetch('engineer', 'PUT', data)
}
// 新增工程师
const addEngineer = (data) => {
    return fetch('engineer', 'POST', data)
}
// 根据id删除工程师
/* const delEngineer = (data) => {
    return fetch('engineer/'+data, 'DELETE', '')
} */
// 获取指定id工程师详情
const engineerDetail = (data) => {
    return fetch('engineer/' + data, 'GET', '')
}
const feedBackList = (data) => {
    return fetch('feedback/list', 'GET', data)
}
const updateFeed = (data) => {
    return fetch('feedback', 'PUT', data)
}
// 获取全部投诉
const complaintList = (data) => {
    return fetch('complaint', 'GET', data)
}
// 处理投诉
const complaintUpdate = (data) => {
    return fetch('complaint', 'PUT', data)
}
// 获取全部投诉状态
const complaintState = (data) => {
    return fetch('complaint/stateItems', 'GET', data)
}
// 获取滤网名称
const strainerList = (data) => {
    return fetch('strainer/list', 'GET', data)
}
const strainerRemove = (data) => {
    return fetch('strainer/' + data, 'DELETE', '')
}
const strainerAdd = (data) => {
    return fetch('strainer', 'POST', data)
}
// 获取零件列表
const partList = (data) => {
    return fetch('spareParts', 'GET', data)
}
// 新增零件
const partAdd = (data) => {
    return fetch('spareParts', 'POST', data)
}

const mealList = (data) => {
    return fetch('meal/list', 'GET', data)
}
const mealListAll = (data) => {
  return fetch('meal/listAll', 'GET', data)
}
const meal = (data) => {
    return fetch('meal', 'POST', data)
}
const deleteMeal = (data) => {
    return fetch('meal/'+data, 'DELETE', '')
}
const updateMeal = (data) => {
    return fetch('meal/'+data.id, 'PUT', data)
}

const repairList = (data) => {
    return fetch('repair', 'GET', data)
}
const repairAssign = (data) => {
    return fetch('repair/appoint', 'POST', data)
}
const repairUpdate = (data) => {
    return fetch('repair', 'PUT', data)
}
const repairDetail = (data) => {
    return fetch('repair/' + data, 'GET', '')
}
const repairPart = (data) => {
    return fetch('repair/sparePart/' + data, 'GET', '')
}
// 删除维修单
const repairDel = (data) => {
    return fetch('repair/delete/' + data, 'DELETE', '')
}
const maintainceList = (data) => {
    return fetch('maintenance/list', 'GET', data)
}
const maintaince = (data) => {
    return fetch('maintenance', 'POST', data)
}
const maintainceDetail = (data) => {
    return fetch('maintenance/' + data, 'GET', '')
}
const maintainceAssign = (data) => {
    return fetch('maintenance/appoint', 'POST', data)
}
// 删除维保单
const maintainceDel = (data) => {
    return fetch('maintenance/' + data, 'DELETE', '')
}
const maintainceUpdate = (data) => {
    return fetch('maintenance', 'PUT', data)
}
const maintainChart=(data)=>{
    return fetch('repair/statistics','GET',data)
}
const customRefresh=(data)=>{
    return fetch('user/refreshData','GET',data)
}
// 零件库excel上传
const sparePartsUploadExcel=(data)=>{
    return fetch('spareParts/uploadExcel','POST',data)
}
// 获取所有的区域
const districtFullList=()=>{
    return fetch('district/fullList','GET','')
}
// 获取可用区域
const districtList=(data)=>{
    return fetch('district/list','GET',data)
}
// 职位
const positionList=(data)=>{
    return fetch('position/list','GET',data)
}
//职位含分页
const positionPageList=(data)=>{
    return fetch('position/page','GET',data)
}
const deletePosition = (data) => {
    return fetch('position/'+ data, 'DELETE', '')
}
const addPosition = (data) => {
    return fetch('position', 'POST', data)
}
const updatePosition = (data) => {
    return fetch('position', 'PUT', data)
}
// 积分/admin/integral/page
// 获取用户积分详情
const integralList=(data)=>{
    return fetch('integral/page','GET',data)
}
// 获取门店列表
const getStoreList=(data)=>{
    return fetch('outlet/page','GET',data)
}
// 新增门店
const addStore=(data)=>{
    return fetch('outlet','POST',data)
}
// 修改门店信息
const editStore=(data)=>{
    return fetch('outlet','PUT',data)
}
// 删除门店
const deleteStore=(data)=>{
        return fetch(`outlet/${data}`,'DELETE','')
    }
// 根据id删除工程师
const delEngineer = (data) => {
    return fetch('engineer/'+data, 'DELETE', '')
}
// 8-18根据id删除维修工单
const cancelRepair = (data) => {
    return fetch('repair/'+data, 'DELETE', '')
}
// 8-18根据id删除维修工单
const cancelSatisfaction = (data) => {
    return fetch('repair/deleteSatisfaction/'+data, 'DELETE', '')
}
// 根据id删除保养工单
const cancelMaintenance = (data) => {
    return fetch('maintenance/cancel/'+data, 'DELETE', '')
}
const commonList = (data) => {
    return fetch('user/cy/' + data, 'GET', '')
}
// 2023-10-18 获取用户手机号对应的地址，替换commonList
const cyFullUser = (data) => {
  return fetch('user/cyFullUser/' + data, 'GET', '')
}
// 获取全部的秒杀活动
const getSeckil = (data) => {
    return fetch('secKill/page' , 'POST', data)
}
// 根据id获取秒杀活动商品的详情
const getSeckill = (data) => {
    return fetch('secKill/' + data , 'POST', '')
}
// 项目列表
const getProjectList=(data)=>{
    return fetch(`project/page`,'GET',data)
}
// 删除项目
const deleteProject=(data)=>{
    return fetch(`project/${data}`,'DELETE','')
}
// 获取可用城市
const getUsefulCity=()=>{
    return fetch('district/fullList','GET','')    
}
// 获取可用城市下区域
const getUsefulArea=(data)=>{
    return fetch(`district/child/${data}`,'GET','')
}
// 新增项目
const addProject=(data)=>{
    return fetch('project','POST',data)
}
// 修改项目
const editProject=(data)=>{
    return fetch('project','PUT',data)
}
// 新增秒杀
const postSeckil = (data) => {
    return fetch('secKill' , 'POST', data)
}
// 模糊查找商品
const fuzzySearch = (data) => {
    return fetch('goods/fuzzySearch' , 'POST', data)
}
// 商品类型
const merchandiseType = (data) => {
    return fetch('goods/merchandiseType' , 'POST', data)
}
// 删除秒杀活动
const delSeckill = (data) => {
    return fetch('activity/' + data , 'DELETE', '')
}
// 修改秒杀活动
const putSeckill = (data) => {
    return fetch('secKill'  , 'PUT', data)
}
// 根据id获取秒杀活动商品的详情
const idSeckill = (data) => {
    return fetch('secKill/' + data , 'POST', '')
}
// 审核退款
const orderRefund = (data) => {
    return fetch('order/checkReturn/'+data, 'PUT','')
}
// 根据id删除零件
const delSpare = (data) => {
        return fetch('spareParts/' + data , 'DELETE', '')
    }
// 根据id修改零件
const putSpare = (data) => {
    return fetch('spareParts/edit', 'PUT', data)
}
// 修改风量滤网
const putStrainer= (data) => {
    return fetch('strainer', 'PUT', data)
}
// 取消已支付订单
const delPay= (data) => {
    return fetch('order/cancel/' + data, 'PUT', '')
}
// 删除订单
const delOrder= (data) => {
    return fetch('order/' + data, 'DELETE', '')
}
// 新增维修工单
const addWarranty= (data) => {
    return fetch('repair', 'POST',data )
}
// 根据id删除零件
const delComment = (data) => {
    return fetch('goods/comment/' + data , 'DELETE', '')
}
// 修改个人密码
const updateCurrent = (data) => {
    return fetch('admin/updateCurrent' , 'PUT', data)
} 
// 获取所有活动
const getActivi=(data)=>{
        return fetch('activity/page','GET',data)
}
// 根据id获得当前活动详情
const getActivityDetail=(data)=>{
    return fetch('activity/'+ data,'GET','')
}
// 新增活动
const postActivi=(data)=>{
    return fetch('activity','POST',data)
}
// 修改活动
const putActivi=(data)=>{
    return fetch('activity','PUT',data)
}
// 获取所有用户
const getAllUser=(data)=>{
    return fetch('user/findAllUser','GET',data)
}
// 获取中奖名单
const getWinningList=(data)=>{
    return fetch('awardRecord/exportWinnings','GET',data)
}
// 关闭活动
const stopActivity=(data)=>{
    return fetch('activity/stop/' + data,'POST','')
}
// 报修管理跳过支付
const putRepair=(data)=>{
    return fetch('repair/' + data,'PUT','')
}
// 保养管理跳过支付
const putMaintenance=(data)=>{
    return fetch('maintenance/' + data,'PUT','')
}
// 获取所属区域
const getAreas=(data)=>{
    return fetch('cyInfo/areas','GET',data)
}
// 获取用户属性
const getCategory=(data)=>{
      return fetch('cyInfo/category','GET',data)
}
// 获取商品分类
const getGoodsCategory=(data)=>{
    return fetch('goods/category/list','GET',data)
}
// 获取百问百答列表
const getAnswerContentList=(data)=>{
    return fetch('answerContent/list','GET',data)
}
// 新增百问百答
const postAnswerContent=(data)=>{
    return fetch('answerContent/','POST',data)
}
// 修改百问百答
const putAnswerContent=(data)=>{
    return fetch('answerContent/','PUT',data)
}
// 删除百问百答
const deleteAnswerContent=(data)=>{
    return fetch(`answerContent/${data}`,'DELETE','')
}
// 百问百答分页
const getAnswerContentPage=(data)=>{
    return fetch(`answerContent/page`,'GET',data)
}
// 查看百问百答详情
const getAnswerContentDetail=(data)=>{
    return fetch(`answerContent/${data}`,'GET','')
}
// -----------------
// 获取线下活动列表
const getOfflineActivityList=(data)=>{
    return fetch('offlineActivity/list','GET',data)
}
// 获取线下活动人员列表
const activityPerList=(data)=>{
    return fetch('offlineActivityUser/pageByOfflineActivityId?'+data,'GET','')
}
    
// 新增线下活动
const postOfflineActivity=(data)=>{
    return fetch('offlineActivity','POST',data)
}
// 修改线下活动
const putOfflineActivity=(data)=>{
    return fetch('offlineActivity','PUT',data)
}
// 删除线下活动
const deleteOfflineActivity=(data)=>{
    return fetch(`offlineActivity/${data}`,'DELETE','')
}
// 线下活动分页
const getOfflineActivityPage=(data)=>{
    return fetch(`offlineActivity/page`,'GET',data)
}
// 查看线下活动详情
const getOfflineActivityDetail=(data)=>{
    return fetch(`offlineActivity/${data}`,'GET','')
}
// ------------------
// 获取社区列表
const getCommunityContentList=(data)=>{
    return fetch('communityContent/list','GET',data)
}
// 新增社区
const postCommunityContent=(data)=>{
    return fetch('communityContent','POST',data)
}
// 修改社区
const putCommunityContent=(data)=>{
    return fetch('communityContent','PUT',data)
}
// 删除社区
const deleteCommunityContent=(data)=>{
    return fetch(`communityContent/${data}`,'DELETE','')
}
// 社区分页
const getCommunityContentPage=(data)=>{
    return fetch(`communityContent/page`,'GET',data)
}
// 查看社区详情
const getCommunityContentDetail=(data)=>{
    return fetch(`communityContent/${data}`,'GET','')
}
// 资讯------------------
// 获取资讯列表
const getInformationContentList=(data)=>{
    return fetch('informationContent/list','GET',data)
}
// 新增资讯
const postInformationContent=(data)=>{
    return fetch('informationContent','POST',data)
}
// 修改资讯
const putInformationContent=(data)=>{
    return fetch('informationContent','PUT',data)
}
// 删除资讯
const deleteInformationContent=(data)=>{
    return fetch(`informationContent/${data}`,'DELETE','')
}
// 资讯分页
const getinformationContentPage=(data)=>{
    return fetch(`informationContent/page`,'GET',data)
}
// 查看社区详情
const getInformationContentDetail=(data)=>{
    return fetch(`informationContent/${data}`,'GET','')
}
// 热点------------------
// 获取热点列表
const getHotspotList=(data)=>{
    return fetch('hotspot/list','GET',data)
}
// 新增热点
const postHotspot=(data)=>{
    return fetch('hotspot','POST',data)
}
// 热点详情
const HotspotDetail=(data)=>{
    return fetch('hotspot/'+data,'GET','')
}
// 修改热点
const putHotspot=(data)=>{
    return fetch('hotspot','PUT',data)
}
// 删除热点
const deleteHotspot=(data)=>{
    return fetch(`hotspot/${data}`,'DELETE','')
}
// 热点分页
const getHotspotPage=(data)=>{
    return fetch(`hotspot/page`,'GET',data)
}
// 客户查询-------------
// 获取有客服的城市列表
const getAdminList=(data)=>{
    return fetch('user/findAllCity','GET',data)
}
// 修改客服信息
const putAdmin=(data)=>{
    return fetch('user/updateAdmin','PUT',data)
}
// 标签类型
const getTagType=(data)=>{
    return fetch(`tag/type/list`,'GET',data)
}
// 标签列表
const getTagList=(data)=>{
    return fetch(`tag/page?`+data,'GET','')
}
// 新增标签
const postTag=(data)=>{
    return fetch(`tag`,'POST',data)
}
// 删除标签
const deleteTag=(data)=>{
    return fetch(`tag/${data}`,'DELETE','')
}
// 获取弹窗列表
const getEjectotList=(data)=>{
    return fetch('popout/page','GET',data)
}
// 新增弹窗
const postEject=(data)=>{
    return fetch('popout','POST',data)
}
// 修改弹窗
const putEject=(data)=>{
    return fetch('popout','PUT',data)
}
// 删除弹窗
const deleteEject=(data)=>{
    return fetch(`popout/${data}`,'DELETE','')
}
// 弹窗类型 article/fullList
const getEjectTypeList=(data)=>{
      return fetch('popout/type/list','GET','')
  }
  // 获取弹窗列表
const getArticleList=(data)=>{
      return fetch('article/fullList','GET',data)
  }
export default {
    getArticleList,
    getEjectTypeList,
    deleteEject,
    putEject,
    postEject,
    getEjectotList,
    deleteTag,
    postTag,
    getTagList,
    getTagType,
    putAdmin,
    getAdminList,
    getHotspotPage,
    deleteHotspot,
    putHotspot,
    postHotspot,
    HotspotDetail,
    getHotspotList,
    getInformationContentDetail,
    deleteInformationContent,
    getinformationContentPage,
    putInformationContent,
    getInformationContentList,
    postInformationContent,
    getCommunityContentDetail,
    deleteCommunityContent,
    getCommunityContentPage,
    putCommunityContent,
    getCommunityContentList,
    postCommunityContent,
    getOfflineActivityDetail,
    deleteOfflineActivity,
    getOfflineActivityPage,
    putOfflineActivity,
    getOfflineActivityList,
    activityPerList,
    postOfflineActivity,
    getAnswerContentList,
    postAnswerContent,
    putAnswerContent,
    deleteAnswerContent,
    getAnswerContentPage,
    getAnswerContentDetail,
    getAreas,
    getCategory,
    getGoodsCategory,
    putMaintenance,
    putRepair,
    stopActivity,
    getWinningList,
    getAllUser,
    putActivi,
    getActivityDetail,
    postActivi,
    getActivi,
    updateCurrent,
    login,
    delPay,
    delOrder,
    putStrainer,
    putSeckill,
    getSeckill,
    delSeckill,
    postSeckil,
    fuzzySearch,
    merchandiseType,
    getSeckil,
    cancelRepair,
    cancelSatisfaction,
    cancelMaintenance,
    commonList,
    cyFullUser,
    deleteStore,
    delEngineer,
    complaintState,
    adminDetail,
    removeAdmin,
    articleList,
    articleTypeList,
    articleByType,
    addAdmin,
    engineerList,
    engineerAllList,
    updateEngineer,
    addEngineer,
    engineerDetail,
    userList,
    adminUpdate,
    adminRemove,
    adminList,
    userDetail,
    feedBackList,
    updateFeed,
    complaintList,
    complaintUpdate,
    strainerList,
    strainerRemove,
    partList,
    strainerAdd,
    partAdd,
    mealList,
    mealListAll,
    meal,
    deleteMeal,
    updateMeal,
    repairList,
    repairDetail,
    repairPart,
    repairDel,
    repairUpdate,
    repairAssign,
    maintainceList,
    maintainceDel,
    maintainceAssign,
    maintainceUpdate,
    maintainceDetail,
    maintaince,
    maintainChart,
    customRefresh,
    sparePartsUploadExcel,
    districtFullList,
    districtList,
    positionList,
    positionPageList,
    deletePosition,
    addPosition,
    updatePosition,
    integralList,
    getStoreList,
    addStore,
    editStore,
    editProject,
    addProject,
    getUsefulArea,
    getUsefulCity,
    deleteProject,
    getProjectList,
    idSeckill ,
    orderRefund,
    delSpare,
    putSpare, 
    addWarranty,
    delComment ,
   

}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal ,message,Form, Select } from 'antd'
import BasicTable from '../../components/Table'
import SearchHeader from '../../components/search_header'
import API from '@/fetch/api'
import Tool from '@/global/tool'

const renderOptions = (item) => (
    <Select.Option value={item.id} key={item.id}>{item.districtName}</Select.Option>)
    // <Select.Option key={item.id}>{item.districtName}</Select.Option>)
class Custom extends Component {
    componentDidMount() {
        // const str = "javasctipt is the best language in the world"

    }
    state = {
        identity:true,
        pageNum:'1',
        pageSize:'10',
        showState:true,
        updateVisible:false,
        data: [],
        allCityData:[],
        form:{},
        districtId:'',
        id:'',
        columns: [
            { title: '客户ID', dataIndex: 'id', key: 'id' },
            { title: '姓名', dataIndex: 'name', key: 'name' },
            { title: '区域', dataIndex: 'dossierAttribution', key: 'dossierAttribution' },
            { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
            { title: '地址', dataIndex: 'address', key: 'address' },
            { title: '联系方式', dataIndex: 'phone', key: 'phone' },
            { title: '属性', dataIndex: 'category', key: 'category' },
            { title: '维修次数', dataIndex: 'repairTimes', key: 'repairTimes' },
            {
                title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                    <div>
                        <span>
                            <Button type='primary' onClick={this.showUpdateModal.bind(this,record)} style={{margin:'0 10px'}}>修改</Button>
                            <Button onClick={() => this.checkCustom(record)}>查看</Button>

                        </span>

                    </div>
                )
            },
        ],
        row: {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);
            },
        },
        total: 1,
        visible: false,
        currentCustom:{}
    }
    componentDidMount() {
        this.getFindAllCity()
        this.getList(`pageNum=${this.state.pageNum}&pageSize=${this.state.pageSize}`)
    }
    checkCustom(data) {
        console.log(this.state.identity,'11111')
        this.setState({
            visible: true,
            currentCustom: data
        })
    }
    searchHandler(data) {
        // console.log(data['客户姓名'],data['联系方式'])
        let obj ={}
        obj.name = data['客户姓名'] || ""
        obj.phone = data['联系方式'] || ""
        // console.log(obj,'用户表obj')
        // console.log(typeof obj)
        if(obj.phone || obj.name  ){
            this.getList(`name=${obj.name}&phone=${obj.phone}`)
        }else{
            message.warning('请输入姓名或手机号！')
        }
        // this.getList({name:obj.name,phone:obj.phone})
        
    }
    showUpdateModal = (record) => {
        console.log(record,this.state.form,'11')
        this.setState({
        id:record.id,
        // name:record.name,
        // content:record.content,
        updateVisible: true,
        });
        // this.props.form.setFieldsValue({ updatecontent: record.content })
    };
    handleChange(value){
        console.log(value,'value')
        this.setState({
            districtId:value
        })
    }
    handleUpdateOk = (e) => {
        // this.state.form.id = this.state.id
        // this.state.form.content = this.state.form.updatecontent
        // this.state.form.name = this.state.form.updateName
        let obj = {}
        obj.districtId = parseInt(this.state.districtId)
        obj.id = e
        console.log(this.state.id,'修改',e)
        // if(JSON.stringify(this.state.form) != "{}"){
            API.putAdmin(obj).then( res => {
                console.log(res,'res')
                if(res.code == 200){
                    message.success('修改成功')
                    this.getList(`pageNum=${this.state.pageNum}`)
                }else{
                    message.errpor('修改失败')
                }
            })
            this.setState({
                updateVisible: false,
            });
      };
    
    handleUpdateCancel = e => {
        this.setState({
            updateVisible: false,
        });
      };
    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };
    handleSelect =(event) =>{
        console.log(event,'认证已否')
        if(event == '已认证'){
            this.state.identity = true
            this.getList(`identity=${this.state.identity}&pageNum=${this.state.pageNum}&pageSize=${this.state.pageSize}`)
        }else{
            this.state.identity = false
            this.getList(`identity=${this.state.identity}&pageNum=${this.state.pageNum}&pageSize=${this.state.pageSize}`)
        }
        console.log(this.state.identity,'认证')
    }
    refreshHandler(){
        API.customRefresh('').then(res =>{
            message.info('刷新成功')
            this.getList(`pageNum=${this.state.pageNum}`)
        })
    }
    changePage(page) {
        // console.log(page,typeof(page),'custom')
        this.setState({
            pageNum:page
        })
        this.getList(`identity=${this.state.identity}&pageNum=${page}`)
    }
    getList(data) {
        API.userList(data).then(res => {
            console.log(res,res.data == null)
            if (res.code == 200 && res.data != null ) {
                // console.log(res, 'this11111')
                if(res.data.result.length == 0){
                    message.warning('无相关数据……')
                }else{
                  res.data.result.forEach(item => {
                    item.createdTime = Tool.timeChecker(item.createdTime)
                  })
                }
                this.setState({
                    data: res.data.result,
                    total: res.data.totalCount
                })
            }
            console.log(this.state.data,'toatl')
        })
       
    }
    // 获取所有有客服的城市
    getFindAllCity(){
        API.getAdminList().then(res=>{
            console.log(res,'客服城市')
            this.setState({
              allCityData:res.data
            })
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <SearchHeader 
                    title="客户信息查询" 
                    firstKey="客户姓名" 
                    secondKey="联系方式" 
                    headerType='refresh-header'
                    showState={this.state.showState} 
                    handleSelect={this.handleSelect}
                    searchHandler={this.searchHandler.bind(this)} 
                    refreshHandler={this.refreshHandler.bind(this)}>
                </SearchHeader>
                <BasicTable  changePage={this.changePage.bind(this)}  data={this.state.data} columns={this.state.columns} row={this.state.row} total={this.state.total} rowKey='id'></BasicTable>
                <Modal
                    title="用户信息详情"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    {this.state.currentCustom.name? <div>
                        <div>用户：{this.state.currentCustom.name}</div>
                        <div>用户ID：{this.state.currentCustom.address}</div>
                        <div>联系方式：{this.state.currentCustom.phone}</div>
                        <div>属性：{this.state.currentCustom.category}</div>
                        <div>地址：{this.state.currentCustom.address}</div>
                        <div>报修次数：{this.state.currentCustom.repairTimes}</div>
                    </div>:''}
                </Modal>
                <Modal
                title="修改区域"
                width={500}
                visible={this.state.updateVisible}
                onOk={this.handleUpdateOk.bind(this,this.state.id)}
                onCancel={this.handleUpdateCancel}
                >
                 <Form.Item label="所在区域">
                        {getFieldDecorator('districtId', {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择区域'
                                }
                            ]
                        })(<Select onChange={this.handleChange.bind(this)} placeholder='请选择地点' style={{ width: 200 }} showSearch>
                            {this.state.allCityData.map(item => (
                                renderOptions(item)
                            ))}
                        </Select>)}
                    </Form.Item>
                </Modal>
            </div>

        )
    }
}
// export default connect()(Custom) 
export default Form.create()(Custom)